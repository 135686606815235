.colorTab {
  color: black !important;
}

.MuiAutocomplete-clearIndicator {
  display: none !important;
}

.card .card-header {
  padding: 4px 15px 0 !important;
  font-size: 16px !important;
}

h2,
.h2 {
  font-size: 1.5em;
  margin-bottom: 0px;
}

p {
  margin-top: 0 !important;
  margin-bottom: 0rem !important;
}

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  padding: 0px !important;
  margin: 0px !important;
  background-color: #ffffff !important;
  border: 1px solid #dddddd !important;
  border-radius: 4px !important;
  color: #66615b !important;
  line-height: normal !important;
  font-size: 14px !important;
  transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out,
    background-color 0.3s ease-in-out !important;
  box-shadow: none !important;
  width: 100% !important;
  height: 40px !important;
}

.content {
  padding: 15px 15px !important;
  margin-top: 63px !important;
}

.main-panel {
  background-color: white !important;
}

.popoverButton {
  border: none !important;
  background: #0f1d37 !important;
  color: white !important;
}
.popoverButton:hover {
  background: #1f335a !important;
}

.card-Header {
  background-color: #0f1d37;
  color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.MuiTypography-h6 {
  font-size: 20px !important;
}
h6,
.h6 {
  text-transform: none;
}

.close {
  color: white !important;
}

/* view pdf */

.viewer-inner-page {
  padding: 0 !important;
}
/* .viewer-layout-toolbar {
  display: none !important;
}
.viewer-layout-container {
  border: 1px solid rgb(255, 255, 255) !important;
  grid-template-rows: none !important;
} */
.MuiInput-root {
  position: relative !important;
  width: -webkit-fill-available !important;
  padding: 3px !important;
}

.nameDcoument {
  margin-bottom: 0px !important;
  text-decoration: none !important;
}
.h5Modifi {
  font-family: unset !important;
  /* line-height: 0em !important; */
  margin-bottom: 0px !important;
}
.viewer-button {
  display: none !important;
}

.viewer-open-file {
  display: none !important;
}

.container-cards {
  margin-left: 15px;
  display: flex;
  flex-wrap: wrap;
}

.card-docs {
  width: 88px;
  text-decoration: none;
  font-size: 8px;
  margin-right: 10px;
}
